export default {
  data() {
    return {

    }
  },
  methods: {
    successNotification(text = 'Успешно') {
      if (text) {
        let timeout;
        clearTimeout(timeout);
        this.$store.commit('changeNotification', true);
        this.$store.commit('changeNotificationType', 'success');
        this.$store.commit('changeNotificationText', text);
        let self = this;
        timeout = setTimeout(function() {
            self.hideNotification();
        }, 6000);
      }
    },
    errorNotification(text = 'Ошибка') {
      if (text) {
        let timeout;
        clearTimeout(timeout);
        this.$store.commit('changeNotification', true);
        this.$store.commit('changeNotificationType', 'error');
        this.$store.commit('changeNotificationText', text);
        let self = this;
        timeout = setTimeout(function() {
          self.hideNotification();
        }, 6000);
      }
    },
    infoNotification(text = 'Инфо') {
      if (text) {
        let timeout;
        clearTimeout(timeout);
        this.$store.commit('changeNotification', true);
        this.$store.commit('changeNotificationType', 'info');
        this.$store.commit('changeNotificationText', text);
        let self = this;
        timeout = setTimeout(function() {
          self.hideNotification();
        }, 6000);
      }
    },
    hideNotification() {
      this.$store.commit('changeNotification', false);
    }
  }
}