import axios from 'axios'
import TokenService from "../services/TokenService";
import router from '../router/index';
import store from '../store'

// export const baseURL = 'http://10.0.40.40:8103/api/';
export const baseURL = 'https://gateadmin.myuzcard.uz/api/';
// export const baseURL = 'http://localhost:7112/api-admin/';
// export const baseURL = 'http://192.168.45.107:7112/api-admin/';


export const http = axios.create({
  baseURL: baseURL
});

function setConfiguration(provider) {
  provider.interceptors.request.use(config => {
    if (store.state.checkToken) {
      store.dispatch('checkRefreshTime');
    }
    let token = TokenService.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['Accept'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
    error => Promise.reject(error)
  );
  provider.interceptors.response.use(res => res.data,
      error => {
        if (error.response && (error.response.status === 401)) {
          localStorage.clear();
          router.push({path: '/login'}).then(() => {})
        } else if (error.response && (error.response.status === 403)) {
          router.push({name: '403'}).then();
        }
        return Promise.reject(error)
      });
}

setConfiguration(http)


export default http
