<template>
  <div class="v-idle">{{ display }}</div>
</template>

<script>

import TokenService from "@/services/TokenService";

export default {
  name: "my-idle",
  props: {
    duration: {
      type: Number,
      // default 5 minutes
      default: 60 * 5,
    },
    events: {
      type: Array,
      // default 5 minutes
      default() {
        return ['mousemove', 'keypress', 'mousedown']
      }
    },
    loop: {
      type: Boolean,
      default: false,
    },
    reminders: {
      type: Array,
      // default 5 minutes
      default() {
        return []
      }
    },
    wait: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      display: '',
      timer: undefined,
      start: 0,
      counter: undefined,
      diff: 0,
      minutes: '',
      seconds: '',
    }
  },
  mounted() {
    setTimeout(() => {
      this.start = Date.now()
      this.setDisplay()
      this.$nextTick(() => {
        this.setTimer()
        for (let i = this.events.length - 1; i >= 0; i -= 1) {
          window.addEventListener(this.events[i], this.clearTimer)
        }
      })
    }, this.wait * 1000)
  },
  methods: {
    setDisplay() {
      // seconds since start
      this.diff = this.duration - (((Date.now() - this.start) / 1000) | 0)
      if (this.diff < 0 && !this.loop) {
        return
      }
      this.shouldRemind()

      // bitwise OR to handle parseInt
      const minute = (this.diff / 60) | 0
      const second = this.diff % 60 | 0

      this.minutes = `${minute < 10 ? '0' + minute : minute}`
      this.seconds = `${second < 10 ? '0' + second : second}`

      this.display = `${this.minutes}:${this.seconds}`;
    },
    shouldRemind() {
      if (this.reminders.length > 0) {
        if (this.reminders.includes(this.diff)) {
          this.remind()
        }
      }
    },
    countdown() {
      this.setDisplay()
      let expiredDate = TokenService.getExpiredDate();
      if (expiredDate) {
        this.$store.dispatch('checkRefreshTime');
      }
      if (this.diff <= 0 && this.loop) {
        // add second to start at the full duration
        // for instance 05:00, not 04:59
        this.start = Date.now() + 1000
      }
    },
    idle() {
      this.$emit('idle');
    },
    remind() {
      this.$emit('remind', this.diff)
    },
    setTimer() {
      this.timer = window.setInterval(this.idle, this.duration * 1000)
      this.counter = window.setInterval(this.countdown, 1000)
    },
    clearTimer() {
      clearInterval(this.timer)
      clearInterval(this.counter)
      this.setDisplay()
      this.start = Date.now()
      this.diff = 0
      this.setTimer()
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.counter)
    for (let i = this.events.length - 1; i >= 0; i -= 1) {
      window.removeEventListener(this.events[i], this.clearTimer)
    }
  },
}
</script>

<style scoped>

</style>