import Vue from 'vue';
import Validation from "@/mixins/Validation";
import Notification from "@/mixins/Notification";
import helpers from "@/mixins/helpers";
import can from "@/mixins/can";
import './Filters'


Vue.mixin(Validation);
Vue.mixin(Notification);
Vue.mixin(helpers);
Vue.mixin({methods: {can}});
