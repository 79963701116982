export default {
    methods: {
        showLoader() {
            this.$store.commit('loading', true);
        },
        hideLoader() {
            this.$store.commit('loading', false);
        },
        getNameById(list = [], id,  keyName = 'name') {
            if (list.length > 0) {
                let item = list.find(x => x.id === id);
                return item[keyName];
            }
        },
        downloadBlobFile(blob, fileName = 'file') {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove()
        },
        base64toBlob(base64Data, contentType) {
            contentType = contentType || '';
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                const begin = sliceIndex * sliceSize;
                const end = Math.min(begin + sliceSize, bytesLength);

                const bytes = new Array(end - begin);
                for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, {type: contentType});
        }

    }
}