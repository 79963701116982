import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from "@/layouts/MainLayout";
import can from "../mixins/can";
import {PERMISSIONS} from "@/utils/constants";
import TokenService from "../services/TokenService";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Auth/Login')
    },
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@/views/MainLayoutView/Dashboard/Dashboard')
            },
            {
                path: '403',
                name: '403',
                component: () => import('@/views/MainLayoutView/CommonPages/403')
            },
// 'Clientpage'
            {
                path: 'get/accesses',
                name: 'getaccesses',
                component: () => import('@/views/MainLayoutView/Pages/Client/GetAccesses')
            },
            {
                path: 'clients',
                name: 'clientSecond',
                component: () => import('@/views/MainLayoutView/Pages/Client/ClientSecond')
            },
            {
                path: 'client/view/:id',
                name: 'clientsView',
                component: () => import('@/views/MainLayoutView/Pages/Client/ClientsView')
            },

            // 'permissionpage''
            {
                path: 'permissions',
                name: 'permissionList',
                component: () => import('@/views/MainLayoutView/Pages/Permission/PermissionList')
            },
            {
                path: 'permission/view/:id',
                name: 'permissionView',
                component: () => import('@/views/MainLayoutView/Pages/Permission/PermissionView')
            },

            // modulpage
            {
                path: 'modules',
                name: 'moduleList',
                component: () => import('@/views/MainLayoutView/Pages/Modules/ModuleList')
            },

            // rolepage
            {
                path: 'roles',
                name: 'roleList',
                component: () => import('@/views/MainLayoutView/Pages/Role/RoleList')
            },
            {
                path: 'role/view/:id',
                name: 'roleView',
                component: () => import('@/views/MainLayoutView/Pages/Role/RoleView')
            },
            // users
            {
                path: 'users',
                name: 'userPage',
                component: () => import('@/views/MainLayoutView/Pages/User/UserPage')
            },
            // reports
            {
                path: 'reports',
                name: 'reportsPage',
                component: () => import('@/views/MainLayoutView/Pages/Reports/ReportsPage')
            },
            {
                path: 'credit-reports',
                name: 'creditTransactions',
                component: () => import('@/views/MainLayoutView/Pages/Reports/CreditTransactions')
            },
            {
                path: 'scoring-reports',
                name: 'scoringReports',
                component: () => import('@/views/MainLayoutView/Pages/Scoring/ScoringReport')
            }
        ]
    },
]
// 'Modulepage'
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!TokenService.getToken()
    if (to.name !== 'login' && !isAuthenticated) {
        return next({name: 'login'})
    } else {
        if (to.meta.permission) {
            if (!can(to.meta.permission)) {
                next({path: '/403'})
            }
            next()
        } else {
            next()
        }
    }
})

export default router
