<template>
  <v-select
      v-model="model"
      :items="items"
      :label="label"
      :item-text="itemText"
      :item-value="itemValue"
      multiple
      hide-details
  >
    <template v-if="allOption" v-slot:prepend-item>
      <v-list-item
          ripple
          @mousedown.prevent
          @click="toggle"
      >
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Все
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "SelectWithCheckBox",
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    itemText: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: true,
    },
    allOption: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectedAllItems() {
      return this.model.length === this.items.length;
    },
    selectSomeItems() {
      return this.model.length > 0 && !this.selectedAllItems;
    },
    icon() {
      if (this.selectedAllItems) return 'mdi-close-box'
      if (this.selectSomeItems) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllItems) {
          this.model = []
        } else {
          let itemIds = [];
          this.items.map(x => {
            itemIds.push(x[this.itemValue]);
          })
          this.model = itemIds;
        }
      })
    },
  }
}
</script>

<style scoped>

</style>