<template>
  <v-app>
    <my-idle @idle="logout" :duration="60*20" />

    <!--  Header  -->
    <v-app-bar
        app
        color="blue accent-4"
        dark
        height="64px"
    >
      <v-app-bar-nav-icon @click="sideBar = !sideBar"></v-app-bar-nav-icon>
      <v-toolbar-title>Gate Admin Panel</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout()">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--  Header  -->

    <!--  SideBar  -->
    <v-navigation-drawer v-model="sideBar" app>
      <v-list style="color: #397dfb" shaped>
        <v-list-item :to="{name:'clientSecond'}">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Clients</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name:'reportsPage'}">
          <v-list-item-icon>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name:'creditTransactions'}">
          <v-list-item-icon>
            <v-icon>mdi-credit-card-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Credit Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name:'scoringReports'}">
          <v-list-item-icon>
            <v-icon>mdi-approximately-equal</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Scoring Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name:'permissionList'}">
          <v-list-item-icon>
            <v-icon>mdi-lock-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Permissions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name:'moduleList'}">
          <v-list-item-icon>
            <v-icon>mdi-source-branch</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Modules</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name:'roleList'}">
          <v-list-item-icon>
            <v-icon>mdi-security</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Roles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name:'userPage'}">
          <v-list-item-icon>
            <v-icon>mdi-account-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

<!--        <v-list v-for="(module, moduleIndex) in modules.filter(i => checkMenu(i.permission))"-->
<!--          :key="moduleIndex"-->
<!--        >-->
<!--          <v-subheader :class="module.bgColor"-->
<!--            v-text="module.title"-->
<!--            class="text-uppercase text-subtitle-1 white&#45;&#45;text rounded-r-xl"-->
<!--          >-->
<!--          </v-subheader>-->
<!--          <v-list-group-->
<!--            v-for="(menu, menuIndex) in module.menus.filter(i => checkMenu(i.permission))"-->
<!--            :key="menuIndex"-->
<!--            no-action-->
<!--          >-->
<!--            <template v-slot:activator>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title-->
<!--                  v-text="menu.name">-->
<!--                </v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </template>-->
<!--            <v-list-item-->
<!--              v-for="child in menu.items.filter(i => checkMenu(i.permission))"-->
<!--              :key="child.title"-->
<!--              :to="child.link"-->
<!--              class="pl-13"-->
<!--            >-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title-->
<!--                  v-text="child.title"-->
<!--                >-->
<!--                </v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->
<!--          </v-list-group>-->
<!--        </v-list>-->
      </v-list>
    </v-navigation-drawer>
    <!--  SideBar  -->
    <v-main>
      <v-container fluid class="h-100">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import {PERMISSIONS} from "@/utils/constants";
// import TokenService from "../services/TokenService";

import MyIdle from "@/components/my-idle.vue";

export default {
  name: "MainLayout",
  components: {MyIdle},
  data: () => ({
    sideBar: true,
    // modules: [
    //   {
    //     title: 'Havo',
    //     bgColor: 'light-blue accent-4',
    //     permission: PERMISSIONS['Pages'],
    //     menus: [
    //       {
    //         name: 'Organizations',
    //         permission: PERMISSIONS['Pages.ORGANIZATIONS'],
    //         items: [
    //           {
    //             title: 'Organizations',
    //             link: '/havo-organizations',
    //             permission: PERMISSIONS['Pages.ORGANIZATIONS.CONTROLLER']
    //           },
    //           {
    //             title: 'Statistics',
    //             link: '/havo-statistics',
    //             permission: PERMISSIONS['Pages.STATISTICS.CONTROLLER']
    //           },
    //           {
    //             title: 'Air bookings',
    //             link: '/havo-air-bookings',
    //             permission: PERMISSIONS['Pages.AIR.BOOKINGS']
    //           },
    //         ]
    //       },
    //     ]
    //   },
    // ],
  }),
  methods: {
    async logout() {
      localStorage.clear();
      await this.$router.push({path: '/login'})
      // localStorage.clear();
      // await this.$router.push({path: '/login'})
      // try {
      //     const res  = await this.$http.post(`Auth/logout?token=${TokenService.getRefreshToken()}`);
      //     if (res) {

      //     }
      // } catch (e) {
      //   this.errorNotification(e.response.data.message)
      // }
    },
    checkMenu(permission){
      return this.$store.state.permissions.find(item => item === permission);
    }
  },
  created() {
  }
}
</script>

<style scoped>
</style>
