import Vue from 'vue'
import Vuex from 'vuex';
import http from "../plugins/axios";
import TokenService from "../services/TokenService";
import Moment from 'moment';
import moment from "moment/moment";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    notification: false,
    notificationType: null,
    notificationText: null,
    permissions: [],
    refreshToken: false,
    leftVidleTime: 0

  },
  mutations: {
    changeNotification(state, value) {
      state.notification = value;
    },
    changeNotificationType(state, type) {
      state.notificationType = type;
    },
    changeNotificationText(state, text) {
      state.notificationText = text;
    },
    loading(state, value) {
      state.loading = value;
    },
    mutatePermissions(state, data) {
      state.permissions = data
    },
    setRefreshToken(state, value) {
      state.refreshToken = value
    },
    changeLeftVidleTime(state, value) {
      state.leftVidleTime = value;
    }
  },
  actions: {
    async getPermissions(context) {
      try {
        const res = await http.get('Auth/getPermissions');
        context.commit('mutatePermissions', res.result)
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async refreshToken(context) {
      TokenService.removeExpiredDate();
      try {
        const res = await http.get(`User/refreshToken?token=${TokenService.getRefreshToken()}`);
        context.commit('setRefreshToken', false);
        TokenService.setToken(res.result.accessToken);
        TokenService.setRefreshToken(res.result.refreshToken);
        localStorage.setItem('expiredDate', moment.utc(res.result.expireDate).local().format());
      } catch (e) {
        console.log(context)
        this.errorNotification(e.response.data.message);
      } finally {
        context.commit('setRefreshToken', false);
      }
    },
    async checkRefreshTime(context) {
      let newDateMilliSeconds = Moment(new Date()).valueOf();
      let expiredDateMilliSeconds = Moment(TokenService.getExpiredDate()).valueOf();
      let diff = expiredDateMilliSeconds - newDateMilliSeconds;
      if (Number(diff) <= 4920000 ) { // 5995542
        context.commit('setRefreshToken', true);
        if (context.state.refreshToken) {
          await context.dispatch('refreshToken');
        }
      }
    }
  },
  modules: {
  }
})
